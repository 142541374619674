import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from './checkLink'
import Text from './text'

const TextWithButton = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      <Text slice={slice} />
      {slice.primary.button_link.url && (
        <div className='mt-5 flex justify-center mx-auto'>
          <CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
        </div>
      )}
    </div>
  )
}

TextWithButton.propTypes = {
  slice: PropTypes.object.isRequired
}

export default TextWithButton
