import React from 'react'
import Card from './card'

const CardDeck = ({ slice }) => {
  var gridClass = 'grid-cols-4'
  if (slice.items.length < 4) {
    gridClass = 'grid-cols-' + slice.items.length
  }
  return (
    <div className={`component-${slice.slice_type} sm:grid`}>
      {slice.primary.deck_title.html && (
        <div className='deck-title title mb-2' dangerouslySetInnerHTML={{__html:slice.primary.deck_title.html}} />
      )}
      <ul className={`md:grid gap-x-4 gap-y-4 ${gridClass}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} key={`card-${index}`} />
          )
        })}
      </ul>
    </div>
  )
}

export default CardDeck
