import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Social from './social'

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicSocial {
      ...PrismicSocialFragment
    }
  }
`;

class Footer extends PureComponent {
  render () {
    return (
      <StaticQuery
        query={`${footerQuery}`}
        render={data => {
          const footerData = data.prismicNavigation.data
          const socialData = data.prismicSocial.data
          return (
            <footer role='banner' className='bg-gray-900 relative border-gray-700 border-t'>
              <div className='max-w-screen-xl mx-auto py-12 px-4 lg:py-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                  <div>
                    <div className='mb-2 font-serif'>{footerData.company_display_name}</div>
                    {footerData.company_address && (
                      <div className='mb-2 w-full inline-flex items-center'>
                        <svg xmlns='http://www.w3.org/2000/svg' className='w-5 mr-2 text-white' height='1em' width='1em' viewBox='0 0 512 512'>
                          <path fill='currentColor' d='M407.579 87.677C376.506 34.053 321.314 1.292 259.939.04c-2.62-.054-5.257-.054-7.878 0-61.374 1.252-116.566 34.013-147.64 87.637-31.762 54.812-32.631 120.652-2.325 176.123l126.963 232.387c.057.103.114.206.173.308 5.586 9.709 15.593 15.505 26.77 15.505 11.176 0 21.183-5.797 26.768-15.505.059-.102.116-.205.173-.308L409.906 263.8c30.304-55.471 29.435-121.311-2.327-176.123zM256 232c-39.701 0-72-32.299-72-72s32.299-72 72-72 72 32.299 72 72-32.298 72-72 72z' />
                        </svg>
                        <div dangerouslySetInnerHTML={{__html:footerData.company_address.html}} />
                      </div>
                    )}
                    {footerData.company_phone && (
                      <div className='mb-2 w-full inline-flex items-center'>
                        <svg xmlns='http://www.w3.org/2000/svg' className='w-5 mr-2 text-white' height='1em' width='1em' viewBox="0 0 384 384">
                          <path fill='currentColor' d="M353.188 252.052c-23.51 0-46.594-3.677-68.469-10.906-10.719-3.656-23.896-.302-30.438 6.417l-43.177 32.594c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208 11.167-20.198 7.635-31.448-7.26-21.99-10.948-45.063-10.948-68.583C132.146 13.823 118.323 0 101.333 0h-70.52C13.823 0 0 13.823 0 30.813 0 225.563 158.438 384 353.188 384c16.99 0 30.813-13.823 30.813-30.813v-70.323c-.001-16.989-13.824-30.812-30.813-30.812z"/>
                        </svg>
                        <a className='text-gray-100 border-b border-transparent hover:border-b-4 hover:border-purple-500 focus:outline-none focus:text-purple-600 transition duration-150 ease-out' href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a>
                      </div>
                    )}
                    <div className='my-1 w-full'>
                      <Social socialData={socialData} />
                    </div>
                  </div>
                  <div>
                    <div className='grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2'>
                      {footerData.body.map((footerList, index) => {
                        return (
                          <div key={`footerList-${index}`}>
                            <h4 className='text-base font-bold leading-none pb-2 mt-3 font-serif uppercase text-purple-100'>{footerList.primary.title}</h4>
                            <ul>
                              {footerList.items.map((footerLink, index) => {
                                return (
                                  <li key={`footer-${index}`}>
                                    <a href={footerLink.link.url} className='relative link font-medium my-2 md:pb-0 inline-block text-base border-b border-transparent hover:border-b-4 hover:border-purple-500'>{footerLink.link_label}</a>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='mt-12 border-t border-gray-700 pt-8'>
                  <p className='text-base leading-6 text-gray-500 text-center'>Made with <a href='https://www.thejuniperstudio.com/' target='_blank' rel='noopener noreferrer' className='duration-500 ease-in-out inline-block hover:-translate-y-1 hover:scale-105 transform'><span role='img' aria-label='Flexed Biceps emoji'>💪</span></a> by <a className='text-purple-300 font-medium hover:underline' href='https://www.thejuniperstudio.com/' target='_blank' rel='noopener noreferrer'>The Juniper Studio</a> &copy; {year}. All rights reserved.</p>
                </div>
              </div>
            </footer>
          )
        }}
      />
    )
  }
}

export default Footer
