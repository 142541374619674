import React from "react"
import PropTypes from "prop-types"
import CheckLink from "../components/checkLink"

const Hero = ({ author, publishDate, slice }) => {
  return (
    <React.Fragment>
      <div
        className={`component-${slice.slice_type} relative overflow-hidden -mb-8`}
      >
        <video
          className="h-screen w-screen object-cover absolute inset-0"
          poster={slice.primary.video_poster.url}
          autoPlay
          muted
          playsInline
          loop
        >
          <source src={slice.primary.video_link.url} type="video/mp4" />
        </video>
        <div className="max-w-screen-xl px-4 mx-auto pt-40 pb-20 md:pb-48">
          <div className="py-10 md:w-1/2 bg-purple-600 bg-opacity-70 p-5 lg:p-10 relative z-10">
            <h1 className="text-white mt-0 mb-3 leading-none relative z-10">
              {slice.primary.title.text}
            </h1>
            <div className="text-xl relative">
              <div className="relative z-10 leading-snug">
                {slice.primary.text.html && (
                  <div
                    className="mb-5"
                    dangerouslySetInnerHTML={{
                      __html: slice.primary.text.html,
                    }}
                  />
                )}
                {slice.primary.button_link.url && (
                  <div>
                    <CheckLink
                      className="button"
                      link={slice.primary.button_link}
                    >
                      {slice.primary.button_text}
                    </CheckLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
  publishDate: PropTypes.string,
  author: PropTypes.object,
}

Hero.defaultProps = {
  publishDate: null,
  author: null,
}

export default Hero
