import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent';
import Header from './header'
import Footer from './footer'

const siteQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`


const Layout = props => {
  return (
    <StaticQuery
      query={`${siteQuery}`}
      render={data => (
        <div className={`layout antialiased text-white bg-gray-900 text-lg`}>
          <Header siteTitle={data.site.siteMetadata.title}/>
          <main role='main' id='main'>{props.children}</main>
          <Footer />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            containerClasses='z-30 fixed inset-x-0 text-white bg-gray-800 p-3 sm:flex justify-center text-center sm:text-left'
            buttonClasses='ml-2 rounded-full border-white border-2 font-medium bg-purple-600 hover:bg-purple-500 text-white px-4 py-2 transform duration-300 ease-in-out transition uppercase'
            declineButtonClasses='ml-2 rounded-md text-white px-4 py-2 transform duration-300 ease-in-out transition uppercase'
            contentClasses='py-2 flex-1 '
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
              We use cookies to collect information about how you use {data.site.siteMetadata.title}. See our <Link to='/privacy-policy' className='border-b-2 border-purple-500 hover:text-purple-400'>Privacy Policy</Link> to see how we use this information.
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
}

export default Layout
