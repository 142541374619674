import React, { PureComponent } from "react"
import CheckLink from "../components/checkLink"

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this)
  }
  container = React.createRef()
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId: false,
      })
    } else {
      this.setState({
        subNavExpandedId: id,
      })
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }
  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        subNavExpandedId: false,
      })
    }
  }

  render() {
    return (
      <li ref={this.container} className="mx-auto text-center relative">
        <button
          className="flex px-1 mx-auto lg:px-2 py-2 pb-1 text-lg lg:text-base xl:text-lg font-serif hover:text-purple-500 active:text-purple-600 text-white outline-none"
          aria-haspopup="true"
          aria-expanded={
            this.state.subNavExpandedId === this.props.children
              ? "true"
              : "false"
          }
          onClick={() => this.toggleSubNav(`${this.props.children}`)}
          onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}
        >
          {this.props.children}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-1 h-5 w-5 md:ml-auto"
          >
            <path
              d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
              fill="currentColor"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <ul
          className={`lg:absolute lg:bg-black lg:rounded-md lg:min-w-max lg:py-3 lg:text-left transform transition duration-150 ease-in-out ${
            this.state.subNavExpandedId === this.props.children
              ? "block"
              : "hidden"
          }`}
        >
          {this.props.items.map((subNav, index) => {
            return (
              <li key={`subNav-${index}`}>
                <CheckLink
                  link={subNav.sub_nav_link}
                  className="block px-6 py-2 hover:text-purple-500 active:text-purple-600 text-white whitespace-no-wrap"
                  activeClassName="active"
                  onClick={() => this.toggleSubNav(`${subNav.primary.label}`)}
                >
                  {subNav.sub_nav_link_label}
                </CheckLink>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
