import React, { PureComponent } from 'react'


class FAQs extends PureComponent {
  constructor() {
    super()
    this.state = {
      FAQExpanded: false
    }
    this.toggleFAQ = this.toggleFAQ.bind(this);
  }
  toggleFAQ(id) {
    if (id === this.state.FAQExpandedId) {
      this.setState({
        FAQExpandedId:false
      })
    } else {
      this.setState({
        FAQExpandedId:id
      })
    }
  }

  render() {
    const slice = this.props.slice
    return (
      <>
        <div className='pb-4'>
          {slice.primary.faq_title && (
            <h2>{slice.primary.faq_title}</h2>
          )}
          {slice.primary.faq_text && (
            <p>{slice.primary.faq_text}</p>
          )}
        </div>
        <ul className={`component-${slice.slice_type}`}>
          {slice.items.map((faq, index) => {
            return (
              <li className='bg-white rounded-md overflow-hidden shadow transform transition-all mb-3' key={`faq-${index}`}>
                <button className='text-gray-900 bg-gray-50 hover:text-purple-500 m-0 text-xl font-medium block cursor-pointer w-full text-left p-4 outline-none' aria-haspopup='true' aria-expanded={this.state.FAQExpandedId === faq.question ? 'true': 'false'} onClick={() => this.toggleFAQ(`${faq.question}`)} onKeyDown={() => this.toggleFAQ(`${faq.question}`)}>
                  {faq.question}
                  <div className='float-right'>
                    {this.state.FAQExpandedId === faq.question ? (
                      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='currentColor'>
                        <path d='M0 0h24v24H0z' fill='none' />
                        <path d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/>
                      </svg>
                    ):(
                      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='currentColor'>
                        <path d='M0 0h24v24H0V0z' fill='none' />
                        <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
                      </svg>
                    )}
                  </div>
                </button>
                <div className={`text text-gray-900 text-lg border-t border-purple-300 p-4 ${this.state.FAQExpandedId === faq.question ? 'block': 'hidden'}`}>
                  <div dangerouslySetInnerHTML={{__html:faq.answer.html}} />
                </div>
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}


export default FAQs
