import React, { PureComponent } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import CheckLink from "./checkLink"
import Dropdown from "./dropdown"

const headerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    logo: file(relativePath: { eq: "companyLogo.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    buggy: file(relativePath: { eq: "BuggyBootcamp.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

class Header extends PureComponent {
  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this)
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded: !this.state.mainNavExpanded,
    })
  }

  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={(data) => {
          const navigationData = data.prismicNavigation.data
          return (
            <header role="banner" className="bg-black z-20 shadow relative">
              <nav
                id="navigation"
                className="mx-auto flex items-center justify-between pl-4"
              >
                <div className="flex items-center flex-shrink-0 text-white z-20 py-4">
                  <Link to="/" className="-mb-20 mr-2 inline-block logo">
                    <Img
                      fixed={data.logo.childImageSharp.fixed}
                      alt={`${navigationData.company_display_name} logo`}
                    />
                  </Link>
                  <Link
                    to="/buggybootcamp"
                    className="-mb-20 inline-block logo"
                  >
                    <Img
                      fixed={data.buggy.childImageSharp.fixed}
                      alt="Buggy Bootcamp Logo"
                    />
                  </Link>
                </div>
                <button
                  type="button"
                  aria-expanded={this.state.mainNavExpanded}
                  onClick={this.toggleMainNav}
                  onKeyDown={this.toggleMainNav}
                  className="flex flex-col font-serif items-end lg:hidden md:text-2xl py-4 mr-4 relative text-white text-xl z-30"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="-mt-1"
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
                      fill="currentColor"
                    />
                  </svg>{" "}
                  menu
                </button>
                <div
                  className="fixed z-10 inset-0 w-full h-screen flex-grow lg:relative lg:inset-auto lg:h-auto lg:flex lg:items-center lg:w-auto lg:justify-end lg:flex-grow-0"
                  hidden={!this.state.mainNavExpanded}
                >
                  <div className="bg-black opacity-90 absolute inset-0 lg:opacity-100 lg:bg-transparent"></div>
                  <ul className="text-center flex h-full lg:h-auto justify-center lg:items-center lg:justify-end flex-col relative px-2 py-2 lg:flex-row">
                    <li className="lg:hidden xl:block mx-auto text-center">
                      <Link
                        to="/"
                        className="block px-1 lg:px-2 py-2 pb-1 text-lg lg:text-base xl:text-lg font-serif hover:text-purple-500 active:text-purple-600"
                      >
                        Home
                      </Link>
                    </li>
                    {navigationData.nav.map((nav, index) => {
                      return (
                        <React.Fragment key={`nav-${index}`}>
                          {nav.items.length > 1 ? (
                            <Dropdown items={nav.items}>
                              {nav.primary.label}
                            </Dropdown>
                          ) : (
                            <li
                              key={`nav-${index}`}
                              className="mx-auto text-center"
                            >
                              <CheckLink
                                className="block px-1 lg:px-2 py-2 pb-1 text-lg lg:text-base xl:text-lg font-serif hover:text-purple-500 active:text-purple-600"
                                activeClassName="text-purple-500"
                                link={nav.primary.link}
                                onClick={this.toggleNav}
                              >
                                {nav.primary.label}
                              </CheckLink>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                    <li className="mt-2 lg:mt-0 ml-2 flex flex-col justify-center">
                      <a
                        className="rounded-md border-2 border-white shadow-lg flex items-center justify-center px-4 py-2 pb-1 transform duration-300 ease-in-out transition focus:outline-none focus:ring text-lg lg:text-base xl:text-lg font-serif rounded bg-purple-600 text-white hover:bg-purple-500 hover:text-white hover:-translate-y-1"
                        href="https://barmingfitness.setmore.com/barmingfitness/class"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Book
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          )
        }}
      />
    )
  }
}

export default Header
