import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'


const Image = ({ slice }) => {
  return (
    <figure className='relative'>
      <Img className='mx-auto rounded-md' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
      <figcaption className='absolute bottom-0 left-0 py-2 px-4 text-purple-100 font-medium bg-gray-900 rounded-bl-md rounded-tr-md'>{slice.primary.image.alt}</figcaption>
    </figure>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Image
