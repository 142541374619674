import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

const Table = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} mt-2`}>
      {slice.items.map((table, index) => {
        return (
          <React.Fragment key={`table-${index}`}>
            <div className='text' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
            <div className='mt-2 overflow-x-scroll'>
              <ReactMarkdown
                plugins={[gfm]}
                children={table.markdown.text}
              />
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

Table.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Table
