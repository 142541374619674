import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'
import { withPreview } from 'gatsby-source-prismic'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = ({ data, location }) => {
  if (!data) return null
  const pageData = data.prismicPage.data

  return (
    <Layout>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} path={location.pathname} />
      {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
        return (
          <div key={`slice-${index}`}>
            <SliceList slice={slice} />
          </div>
        )
      })}
    </Layout>
  )
}

export default withPreview(PageTemplate)
