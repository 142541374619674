import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from './checkLink'
import Img from 'gatsby-image'

const BannerWithCaption = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} relative overflow-hidden py-10`}>
      <div className='wrapper md:max-w-2xl md:w-1/2'>
        <div className='caption mx-auto max-w-screen-xl md:pr-10'>
          <div className='mb-3 md:mx-0'>
            <div className='title' dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
            {slice.primary.text.html && (
              <div dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
            )}
          </div>
          {slice.primary.button_text && (
            <div className='button-wrapper mt-3 mb-5 sm:mb-0 sm:mt-5'>
              <CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
            </div>
          )}
        </div>
      </div>
      <div className='banner md:absolute md:inset-y-0 md:right-0 md:w-1/2'>
        <div className='image-wrapper rounded-md relative overflow-hidden lg:object-fill h-full w-full'>
          <Img
            className='w-full h-full object-cover'
            fluid={slice.primary.image.fluid}
            alt={slice.primary.image.alt || ''}
          />
        </div>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
