import React from 'react'
import PropTypes from 'prop-types'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Carousel from './carousel'
import Contact from './contact'
import Faq from './faq'
import Hero from './hero'
import HeroVideo from './heroVideo'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Table from './table'
import TextWithButton from './textWithButton'
import Video from './video'

const SliceList = ({ slice }) => {
  return (
    <>
      {slice.slice_type === 'banner_with_caption' && (
        <div className='text-white'>
          <div className='absolute w-full z-0' style={{height:'60%'}}>
            <div className='relative h-full w-full top-0 left-0 transform -skew-y-6 overflow-hidden bg-purple-600 md:-mt-20' />
          </div>
          <div className='max-w-screen-xl mx-auto px-4 relative z-10 md:my-10 '>
            <BannerWithCaption slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='max-w-screen-xl mx-auto pb-8 px-4 md:pb-16'>
          <Contact />
        </div>
      )}
      {slice.slice_type === 'faq' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <Faq slice={slice} />
        </div>
      )}
      {slice.slice_type === 'hero' && (
        <Hero slice={slice} />
      )}
      {slice.slice_type === 'hero_video' && (
        <HeroVideo slice={slice} />
      )}
      {slice.slice_type === 'image' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <Image slice={slice} />
        </div>
      )}
      {slice.slice_type === 'image_gallery' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          {slice.items !== null && slice.items.length > 0 && (
            <Carousel autoplay delayLength={6000} slice={slice} />
          )}
        </div>
      )}
      {slice.slice_type === 'map' && (
        <div className='max-w-5xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <Map slice={slice} />
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='max-w-5xl mx-auto py-5 lg:py-10 px-16 relative'>
          <Quote slice={slice} />
        </div>
      )}
      {slice.slice_type === 'table' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <Table slice={slice} />
        </div>
      )}
      {slice.slice_type === 'text_with_button' && (
        <div className='max-w-screen-xl mx-auto px-4 py-5 lg:py-10 relative z-10'>
          <TextWithButton slice={slice} />
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-screen-xl mx-auto px-4 md:pb-20 md:my-10 relative z-10'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default SliceList
