import React from 'react'

const Contact = () => {
  return (
    <div className='md:w-1/2'>
      <form
        name='contact'
        method='POST'
        action='/success'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        className='col-span-2'
      >
        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />

        <div className='mb-4'>
          <label className='block mt-5 mb-3' htmlFor='name'>What's your name?</label>
          <input className='appearance-none border-2 bg-gray-900 rounded-md w-full py-3 px-5 focus:outline-none focus:ring' type='text' name='name' id='name' required />
        </div>
        <div className='mb-4'>
          <label className='block mt-5 mb-3' htmlFor='email'>What's your email address?</label>
          <input className='appearance-none border-2 bg-gray-900 rounded-md w-full py-3 px-5 focus:outline-none focus:ring' type='email' name='email' id='email' required />
        </div>
        <div className='mb-4'>
          <label className='block mt-5 mb-3' htmlFor='message'>How can we help?</label>
          <textarea className='appearance-none border-2 bg-gray-900 rounded-md w-full py-3 px-5 focus:outline-none focus:ring' name='message' id='message' required></textarea>
        </div>
        <button type='submit' className='button'>Send your enquiry</button>
      </form>
    </div>
  )
}

export default Contact
