import React from 'react'
import CheckLink from './checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {
  return (
    <li className='flex flex-col mb-4 md:mb-0'>
      <div className={`component-card overflow-hidden flex flex-col flex-1 relative rounded-md ${fields.button_link.url && !fields.button_text ? ('cursor-pointer transform translate ease duration-300 hover:scale-105 hover:shadow-sm hover:bg-purple-900') : ('')}`}>
        {fields.image.fluid && (
          <div className='bg-gray-200'>
            <Img fluid={fields.image.fluid} alt={fields.image.alt} />
          </div>
        )}
        <div className='flex flex-col flex-grow m-4'>
          <div className='text-purple-600 leading-none mb-2'>
            <div dangerouslySetInnerHTML={{__html:fields.title.html}} />
          </div>
          <div className='text'>
            <div dangerouslySetInnerHTML={{__html:fields.text.html}} />
          </div>
          {fields.button_link.url && (
            <div className='mt-auto mx-auto pt-3'>
              <CheckLink className={`${fields.button_text ? ('button border-purple-600 text-purple-600 hover:text-white'):('stretched-link')}`} link={fields.button_link}>
                {fields.button_text ? (
                  fields.button_text
                ):(
                  <span className='sr-only'>View</span>
                )}
              </CheckLink>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default Card
