import React from 'react'
import PropTypes from 'prop-types'


const Video = ({ slice }) => {
  if (slice.primary.embed_video !== null) {
    var padding = {
      paddingBottom: 100 / (slice.primary.embed_video.width / slice.primary.embed_video.height) + '%'
    }
    var embed = slice.primary.embed_video.embed_url
    if (embed !== null && embed.includes('watch')) {
      embed = 'https://www.youtube.com/embed/' + embed.split('v=')[1]
      var ampersandPosition = embed.indexOf('&')
      if(ampersandPosition !== -1) {
        embed = embed.substring(0, ampersandPosition)
      }
    }
  }
  return (
    <React.Fragment>
      {slice.primary.video_title.html && (<div className='mb-2' dangerouslySetInnerHTML={{__html:slice.primary.video_title.html}} />)}
      {slice.primary.embed_video ? (
        <div className='relative' style={padding}>
          <iframe className='absolute inset-0' width='100%' height='100%' title={slice.primary.embed_video.title} src={`${embed}?feature=oembed`} allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
        </div>
      ):(
        <video controls poster={slice.primary.video_poster.url}>
          <source src={slice.primary.video_link.url} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </React.Fragment>
  )
}

Video.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Video
