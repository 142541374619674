import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'


const socialQuery = graphql`
  {
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    youtube: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Social = props => {
  const socialData = props.socialData
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={data => (
        <React.Fragment>
          {socialData.body.map((social, index) => {
            var socialImage = data[social.primary.social_network.toLowerCase()].childImageSharp.fixed
            return (
              <a key={`social-${index}`} href={social.primary.social_network_url.url} targe='_blank' rel='noopener noreferrer' className='social-icon'>
                <Img fixed={socialImage} alt={social.primary.social_network} className={`social-${social.primary.social_network} h-10 w-10 m-2 transform hover:scale-110 ease duration 300`} loading='lazy' />
              </a>
            )
          })}
        </React.Fragment>
      )}
    />
  )
}

Social.propTypes = {
  socialData: PropTypes.object.isRequired,
}

export default Social
