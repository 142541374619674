import React from "react"
import PropTypes from "prop-types"
import CheckLink from "../components/checkLink"

const Hero = ({ slice }) => {
  return (
    <div
      className={`component-${slice.slice_type} bg-cover bg-center relative overflow-hidden`}
      style={{ backgroundImage: "url(" + slice.primary.image.url + ")" }}
    >
      <div className="max-w-screen-xl px-4 mx-auto pt-40 pb-32 md:pb-48">
        <div className="py-10 md:w-1/2 bg-purple-600 bg-opacity-70 p-5 p-10">
          <h1 className="text-white mt-0 mb-3 leading-none relative">
            {slice.primary.title.text}
          </h1>
          <div className="text-lg relative">
            <div className="absolute inset-0 opacity-80" />
            <div className="relative z-10 leading-snug">
              {slice.primary.text && (
                <div
                  className="mb-5"
                  dangerouslySetInnerHTML={{ __html: slice.primary.text.text }}
                />
              )}
              {slice.primary.button_link && slice.primary.button_text && (
                <CheckLink className="button" link={slice.primary.button_link}>
                  {slice.primary.button_text}
                </CheckLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Hero
