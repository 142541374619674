import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Gallery = ({ slice }) => {
  return (
    <React.Fragment>
      {slice.primary.gallery_name && (
        <div className='mb-3' dangerouslySetInnerHTML={{__html:slice.primary.gallery_name.html}} />
      )}
      <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
        {slice.items.map((image, index) => {
          return (
            <li className='relative rounded-md overflow-hidden' key={`image-${index}`}>
              <Img fluid={image.image.fluid} alt={image.image.alt || ''} />
              {image.captions.html && (
                <div className='absolute bottom-0 left-0 py-1 px-3 text-sm text-purple-100 font-medium bg-gray-900 rounded-tr-md' dangerouslySetInnerHTML={{__html:image.captions.html}} />
              )}
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

Gallery.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Gallery
